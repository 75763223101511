import ninja from "./ninja";
import * as Turbo from "@hotwired/turbo";

import "@fontsource/inter/variable.css";
import "index.css";

import Alpine from "alpinejs";

/*
 * @typedef { import("alpinejs").AlpineComponent } AlpineComponent
 * @type {AlpineComponent}
 */
Alpine.store("darkMode", {
  on: false,

  init() {
    this.on = window.matchMedia("(prefers-color-scheme: dark)").matches;
  },

  toggle() {
    console.log("toggle", this.on);
    this.on = !this.on;
  },
});

Alpine.magic("share", async () => {
  const title = document.title;
  const url = window.location.href;

  if ("share" in navigator) {
    try {
      await navigator.share({ title, url });
    } catch (err) {
      console.error(err);
      window.open(`https://twitter.com/intent/tweet?text=${title}&url=${url}`);
    }
  } else {
    window.open(`https://twitter.com/intent/tweet?text=${title}&url=${url}`);
  }
});

Alpine.plugin(ninja);

// @type any
window.Alpine = Alpine;
window.Alpine.start();

// Import all JavaScript & CSS files from src/_components
// import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}";

// Ninja Keys
document.addEventListener("ninja:open", () => {
  console.log("ninja:open");
  const menu = document.querySelector("ninja-keys");
  menu.open();
});

//  TODO: Uncomment this to enable plausible.io analytics with turbo
// window.plausible =
//   window.plausible ||
//   function () {
//     (window.plausible.q = window.plausible.q || []).push(arguments);
//   };

// document.addEventListener("turbo:load", function () {
//   plausible("pageview");
// });
