import * as Turbo from "@hotwired/turbo";
import "ninja-keys";

export default function (Alpine) {
  Alpine.directive("ninja", (el) => {
    const ninja = el.firstElementChild;

    ninja.data = [
      {
        id: "Page",
        title: "Search pages...",
        children: ["Uses", "Links", "Analytics"],
        hotkey: "ctrl+1",
        section: "Quick Nav",
        handler: () => {
          ninja.Go({ parent: "Page" });
          return { keepGo: true };
        },
      },
      {
        id: "Collection",
        title: "Search collections...",
        children: ["Timeline"],
        hotkey: "ctrl+0",
        section: "Quick Nav",
        handler: () => {
          ninja.Go({ parent: "Collection" });
          return { keepGo: true };
        },
      },
      {
        id: "Home",
        title: "Home",
        hotkey: "ctrl+H",
        section: "Featured",
        handler: () => {
          Turbo.visit("/");
        },
      },
      {
        id: "About",
        title: "About",
        hotkey: "ctrl+A",
        section: "Featured",
        handler: () => {
          Turbo.visit("/about");
        },
      },

      {
        id: "Speaking",
        title: "Speaking",
        section: "Featured",
        handler: () => {
          Turbo.visit("/speaking");
        },
      },
      {
        id: "Posts",
        title: "Posts",
        hotkey: "ctrl+N",
        section: "Featured",
        handler: () => {
          Turbo.visit("/posts");
        },
      },
      {
        id: "Tags",
        title: "Tags",
        section: "Featured",
        handler: () => {
          Turbo.visit("/tags");
        },
      },
      {
        id: "Analytics",
        title: "Analytics",
        hotkey: "ctrl+L",
        parent: "Page",
        handler: () => {
          Turbo.visit("/analytics");
        },
      },
      {
        id: "Links",
        title: "Links",
        hotkey: "ctrl+L",
        parent: "Page",
        handler: () => {
          Turbo.visit("/links");
        },
      },
      {
        id: "Uses",
        title: "Uses",
        parent: "Page",
        handler: () => {
          Turbo.visit("/uses");
        },
      },
      {
        id: "Dashboard",
        title: "Dashboard",
        parent: "Page",
        handler: () => {
          Turbo.visit("/dashboard");
        },
      },
      {
        id: "Projects",
        title: "Projects",
        parent: "Collection",
        handler: () => {
          window.location.href = "/projects";
        },
      },
      {
        id: "Newsletter",
        title: "Newsletter",
        parent: "Collection",
        handler: () => {
          window.location.href = "/newsletter";
        },
      },
      {
        id: "Timeline",
        title: "Timeline",
        hotkey: "ctrl+S",
        parent: "Collection",
        handler: () => {
          Turbo.visit("/timeline");
        },
      },
      {
        id: "Theme",
        title: "Change theme...",
        children: ["Light Theme", "Dark Theme", "System Theme"],
        hotkey: "ctrl+T",
        section: "Actions",
        handler: () => {
          ninja.Go({ parent: "Theme" });
          return { keepGo: true };
        },
      },
      {
        id: "Light Theme",
        title: "Change theme to Light",
        parent: "Theme",
        handler: () => {
          Alpine.store("darkMode").toggle();
        },
      },
      {
        id: "Dark Theme",
        title: "Change theme to Dark",
        parent: "Theme",
        handler: () => {
          Alpine.store("darkMode").toggle();
        },
      },
    ];
  });
}
